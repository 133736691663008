var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("CoordinateManagementTemplate", {
        attrs: {
          images: _vm.images,
          planPage: _vm.planPage,
          temporaryId: _vm.temporaryId,
          onComplete: _vm.onComplete,
          completeButtonText: _vm.$t("button.register"),
          dialogTitle: _vm.$t("title.agency.applicationFormRegisterConfirm"),
          completeText: _vm.$t("description.confirmCreateApplicationForm"),
          type: "create"
        }
      }),
      _c("ErrorDialog", { ref: "errorDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }