<template>
  <span>
    <CoordinateManagementTemplate
      :images="images"
      :planPage="planPage"
      :temporaryId="temporaryId"
      :onComplete="onComplete"
      :completeButtonText="$t('button.register')"
      :dialogTitle="$t('title.agency.applicationFormRegisterConfirm')"
      :completeText="$t('description.confirmCreateApplicationForm')"
      type="create"
    />
    <ErrorDialog ref="errorDialog" />
  </span>
</template>

<script>
import CoordinateManagementTemplate from '@/pages/agency/templates/CoordinateManagementTemplate';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import { mapGetters } from 'vuex';
import { postApplicationFormMaster } from '@/apis/agency/applicationForms';

export default {
  name: 'CreateCoordinateManagement',

  components: {
    CoordinateManagementTemplate,
    ErrorDialog,
  },
  data() {
    return {
      images: this.applicationFormImages(),
      planPage: null,
      coordinateManagementList: [],
      temporaryId: 1,
    };
  },
  async mounted() {
    if (!this.applicationFormPdf()) this.$router.push('/add_application_form');
  },
  methods: {
    ...mapGetters('applicationForm', [
      'applicationFormImages',
      'applicationFormPdf',
      'applicationFormDetails',
      'applicationFormPrintLocation',
    ]),
    async onComplete(coordinateManagementList) {
      if (coordinateManagementList.length > 0) {
        coordinateManagementList = coordinateManagementList.map(
          coordinateManagement => {
            return {
              xCoordinate: coordinateManagement.xCoordinate,
              yCoordinate: coordinateManagement.yCoordinate,
              width: coordinateManagement.width,
              height: coordinateManagement.height,
              screenPage: coordinateManagement.screenPage,
              pdfPage: coordinateManagement.pdfPage,
              description: coordinateManagement.description,
              singlePlanConfirmation: coordinateManagement.singlePlanConfirmation,
              multiplePlansConfirmation: coordinateManagement.multiplePlansConfirmation,
              planFlg: coordinateManagement.planFlg,
              importantFlg: coordinateManagement.importantFlg,
              skipFlg: coordinateManagement.skipFlg,
              clauseFlg: coordinateManagement.clauseFlg,
              modificationItems: coordinateManagement.modificationItems,
            };
          }
        );

        const result = await postApplicationFormMaster(
          this.applicationFormPdf(),
          this.applicationFormDetails(),
          coordinateManagementList,
          this.applicationFormPrintLocation()
        ).catch(err => {
          console.log(err);
        });

        if (result) {
          this.$router.push('/application_form_list');
        } else {
          this.$refs.errorDialog.open(
            this.$t('title.agency.applicationFormRegisterError'),
            this.$t('error.applicationForMastermRegisterBadRequest')
          );
        }
      }
    },
  },
};
</script>
